<template>
  <div class="login">
    <NavBar />
    <form class="login-form">
        <h1>Ingresa</h1>
        <input type="text" placeholder="Numero de empleado" v-model="empleado" autocomplete="username">
        <input type="password" placeholder="contraseña" v-model="password" autocomplete="current-password">
        <p> <input type="checkbox" name="" id=""> Acepto términos y condiciones</p>
        <div class="btn" @click="login"> <span class="bold">Impulso</span> Digital</div>
        <div class="msg">{{msg}}</div>
        <div class="logo-monte">
          <img src="../assets/logo-monte-blanco.png" alt="">
        </div>
        <p class="notice">Para cualquier tema relacionado al acceso a la plataforma o con tu usuario y contraseña ponte en <a href="mailto:comunicacion_interna@montepiedad.com.mx">contacto aquí</a></p>
    </form>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Login',
  components: {
    NavBar,
    Footer
  },
  data(){
    return{
      empleado:"",
      password:"",
      msg:"",
      user:"",
      loading:false,
    }
  },
  mounted(){
    //TODO redirect is firebase auth
    localStorage.removeItem('user');
    console.log(this.$route.query)
  },
  computed:{
    nextRoute () {
      return this.$route.query.redirect || '/contenido'
    }
  },
  metaInfo(){
    return { 
     title: "Login Impulso Digital",
    }
  },
  methods:{
    login(){
      //TODO log user
      //
      this.msg = "";
      if (!this.loading){
        this.loading = true;
        var self = this;
        if(this.empleado && this.password){
          this.msg = "Espera...";
          
          axios({
            method: 'post',
            url: 'https://us-central1-apps-backend-functions.cloudfunctions.net/loginImpulsoDigital',
            data: {
              user: this.empleado,
              pass: this.password
            }
          })
          .then(response => {
            console.log(response);
            if(response.data.user){
              localStorage.setItem('user', response.data.user);
              gtag('config', 'G-K89ZDNW5Z1', {
                    'user_id': self.empleado 
                  });
                  gtag('set', 'user_properties', { 
                    'usuario' : self.empleado 
                  });
                  console.log("enviando id a analytics:"+self.empleado);
                  self.$router.replace(self.nextRoute);
              /*
              firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
              .then(() => {
                firebase.auth().signInWithCustomToken(response.data.user)
                .then((userCredential) => {
                  // Signed in
                  //console.log(userCredential);
                  //Enviamos ID a GA4
                  gtag('config', 'G-K89ZDNW5Z1', {
                    'user_id': self.empleado 
                  });
                  gtag('set', 'user_properties', { 
                    'usuario' : self.empleado 
                  });
                  console.log("enviando id a analytics:"+self.empleado);
                  self.$router.replace(self.nextRoute);
                  // ...
                })
                .catch((error) => {
                  this.msg = "Ocurrio un error, revisa tu usuario y contraseña";
                  console.log(error);
                  // ...
                });
              })*/
            }  else{
              this.msg = "Ocurrio un error, revisa tu usuario y contraseña";
            }
          })
          .catch(error => {
            console.log(error);
            this.msg = "Ocurrio un error, revisa tu usurio y contraseña";
          })
        } else {
          this.msg ="Debes ingresar tu número de empleado y la contraseña";
        }
      }
      
    },


  }
}
</script>


<style lang="scss">
.login{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:center;
  flex:1;
  background: url('../assets/back.png') no-repeat center center;
  background-size: cover;

  a{
    color:#fff;
    text-decoration: none;
    font-weight:bold;
  }
}

.logo-monte{
  margin:25px auto;
  padding:0;
  width:150px;
  text-align: center;
  img{
    width:100%;
  }
}

.login-form{
    margin: 0 auto;
    padding: 10px 15px;
    text-align:left;
    width: 80%;
    max-width: 400px;
    background-color: #000;

    input{
        width:80%;
        margin: 10px auto;
        background-color: #343434;
        border: 1px solid #000;
        padding:7px 12px;
        color: #fff;
    }

    input[type="checkbox"]{
        width:20px;
    }

    .btn{
        background-color: #3e9dd0;
        border: 1px solid #000;
        text-align: center;
        margin: 15px auto;
        width: 200px;
        vertical-align: middle;
        padding:15px 0;
        cursor: pointer;
    }

    .bold{
        font-weight: bold;
    }

    .msg{
      color:red;
      font-size:1rem;
    }

    .notice{
      font-size: .8rem;
      margin-top:50px;
      font-weight:300;

    }
}
</style>