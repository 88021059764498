<template>
  <div class="home" @click="iniciar">
    <video :src="vid" autoplay playsinline muted></video>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data(){
    return{
      vid:"/intro.mp4"
      //vid:"https://firebasestorage.googleapis.com/v0/b/monteflix-33ebe.appspot.com/o/Intro%20impulsodigital%20small.mp4?alt=media&token=6f3c062a-ec87-464f-90a8-ab0bd836de8a"
    }
  },
  mounted() {
    const video = document.querySelector('video');

    video.addEventListener('ended', (event) => {
      this.iniciar();
    });
  },
  methods:{
    iniciar(){
      this.$router.push("/login");
    }
  }
}
</script>

<style lang="scss">
.home{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center;
  flex:1;

  video{
    margin:0 auto;
    width: 100%;
    max-width:1400px;
  }


}
</style>