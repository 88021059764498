import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title:"Inicio"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title:"Login"
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title:"Acerca"
    }
  }
  ,
  {
    path: '/contenido',
    name: 'Contenido',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contenido.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title:"Contenido"
    }
  }
  ,
  {
    path: '/seccion/:id',
    name: 'Seccion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Seccion.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: false,
    }
  }
  ,
  {
    path: '/capitulos',
    name: 'Capitulos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Capitulos.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title:"Capitulos"
    }
  }
  ,
  {
    path: '/video/:seccion/:id',
    name: 'Video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Video.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title:"Video :id"
    }
  }
  ,
  {
    path: '/revista/:seccion/:id',
    name: 'Revista',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Revista.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title:"Revista :seccion"
    }
  }
  ,
  {
    path: '/admin/login',
    name: 'Admin Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Login.vue'),
  }
  ,
  {
    path: '/admin/portadas',
    name: 'Admin Portadas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Contenido.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
  ,
  {
    path: '/admin/revistas',
    name: 'Admin Revistas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Revistas.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
  ,
  {
    path: '/admin/boletas',
    name: 'Admin Boletas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Boletas.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
  ,
  {
    path: '/admin/capsulas',
    name: 'Admin Capsulas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Capsulas.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
  ,
  {
    path: '/admin/dialogos',
    name: 'Admin Dialogos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Dialogos.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
  ,
  {
    path: '/admin/conecta',
    name: 'Admin Conecta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Conecta.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
  ,
  {
    path: '/admin/comunicados',
    name: 'Admin Comunicados',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Comunicados.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
  ,
  {
    path: '/admin/camp',
    name: 'Admin Campañas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Camp.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
  ,
  {
    path: '/admin/tulabor',
    name: 'Tu Labor Transforma',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/TuLaborTransforma.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
  ,
  {
    path: '/admin/extra/:id',
    name: 'Extra',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Extra.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const isAdmin = to.matched.some(x => x.meta.isAdmin)
  //const currentUser = firebase.auth().currentUser;
  
  
  if(requiresAuth){
    const currentUser = localStorage.getItem('user')
    if(!currentUser){
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
        query: {
          redirect: to.fullPath
        }
      })  
    }
    /*
    firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
      console.log(idToken);
    }).catch(function(error) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
        query: {
          redirect: to.fullPath
        }
      })
    });
    */
    if(!isAdmin && currentUser){
      next()
    } else  if (isAdmin && currentUser){
      firebase.auth().currentUser.getIdTokenResult()
      .then(function ({claims}){
        if(claims.admin){
          next()
        } else {
          next({
            path: '/admin/login',
            params: { nextUrl: to.fullPath },
            query: {
              redirect: to.fullPath
            }
          })
        }
      })
    }
    else if(isAdmin && !currentUser){
      next({
        path: '/admin/login',
        params: { nextUrl: to.fullPath },
        query: {
          redirect: to.fullPath
        }
      })
    } else if (!currentUser){
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
        query: {
          redirect: to.fullPath
        }
      })
    }

  } else {
    next();
  }

  /*
  if (requiresAuth && isAdmin && !currentUser) next({ path: '/admin/login', query: { redirect: to.fullPath } })
  else if (requiresAuth && !isAdmin && !currentUser) next({ path: '/login', query: { redirect: to.fullPath } })
  else if (!requiresAuth && !isAdmin) next()
  else next()*/
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  });
}

/*
const DEFAULT_TITLE = 'Impulso Digital';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});
*/
export default router
