<template>
    <div id="app">
      <router-view/>
    </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  metaInfo(){
    return { 
        title: "Impulso Digital | NMP",
        meta: [
            { name: 'description', content:  'Revista Interna del NMP'},
            { property: 'og:title', content: "Impulso Digital | NMP"},
            { property: 'og:site_name', content: 'Impulso Digital'},
            {property: 'og:type', content: 'website'},    
            {name: 'robots', content: 'index,nofollow'} 
        ]
    }
  }
};
</script>

<style lang="scss">
*, *:before, *:after {
  box-sizing: inherit;
}

body, html{
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Noto Sans JP', sans-serif;
  background-color:#171717;
  box-sizing: border-box;
}

#app {
  margin:0 auto;
  padding:0;
  width:100%;
  height:100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background-color:#171717;
  max-width:1920px;
  display: flex;
  flex-direction:column;
  position: relative;
}

.vidthumb{
  max-width:120px;
  margin:15px auto;
}


</style>