import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {createClient} from '@sanity/client'


Vue.use(Vuex)
var db = firebase.firestore();

const client = createClient({
  projectId: 'j5fl1tzn',
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
  perspective: 'published'
})

export default new Vuex.Store({
  state: {
    user:{
      isAdmin:false,
    },
    activeSection:null,
    activeTitle:null,
    activeChapter:0,
    showAcerca:false,
    sections:[],
    activeSectionContent:[],
  },
  mutations: {
    setSection(state,s){
      state.activeSection = s;
    },
    setSectionByID(state,id){
      state.activeSection = state.sections.find(s=>s.slug.current==id);
    },
    setTitle(state,t){
      state.activeTitle = t;
    },
    setChapter(state,c){
      state.activeChapter = c;
    }
  },
  actions: {
    async login({commit},{empleado,password}){
      return new Promise((resolve,reject)=>{
        db.collection("impulsodigital").where("bd", "==", password).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if(doc.id){
              resolve("ok");
            }
            else{
              reject("error");
            }
          });
        });
      })
    },
    async getSections({commit,state}){
      return new Promise((resolve,reject)=>{
        client
        .fetch(`*[_type == 'category' && active]`)
        .then((data) => {
          state.sections = data;
          resolve();
        })
        .catch((error) => {
          console.error("Oh no, error occured: ", error);
          reject();
        });

        /*
        db.collection("portadas").get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            state.sections.push(doc.data());
          });
          resolve();
        });*/
       
      })
    },
    async getFondos({commit,state}){
      return new Promise((resolve,reject)=>{
        const options = {
          method: 'GET',
          url: 'https://us-central1-monteflix-33ebe.cloudfunctions.net/getFondos',
          headers: {
            'accept': 'application/json',
          }
        };
        //console.log("checking covers")
        axios.request(options).then(function (response) {
          console.log(response);
          state.fondos = response.data.fondos
          resolve("done");
        }).catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
          }
          reject("error");
        });
      })
    },
    async getFile({commit,state},id){
      return new Promise((resolve,reject)=>{
        client.fetch(`*[_type == 'post' && _id == '${id}'] {
          title,
          "pdfUrl": file.asset->url
        }`)
        .then((data) => {
          console.log(data);
          resolve(data[0].pdfUrl);
        })
        .catch((error) => {
          console.error("Oh no, error occured: ", error);
          reject();
        });
      })
    },

    async setSection({commit,state},s){
      return new Promise((resolve,reject)=>{
        commit("setSection",s);
      })
    },
    async setSectionByID({commit,state},id){
      return new Promise((resolve,reject)=>{
        
        commit("setSectionByID",id);
        resolve("done");
      })
    },
    async setTitle({commit,state},t){
      return new Promise((resolve,reject)=>{
        commit("setTitle",t);
        resolve("done");
      })
    },
    async setChapterBySlug({commit,state},slug){
      return new Promise((resolve,reject)=>{
        client.fetch(`*[_type == 'post' && slug.current == '${slug}']`)
        .then((data) => {
          commit("setChapter",data[0])
          resolve(data[0]);
        })
        .catch((error) => {
          console.error("Oh no, error occured: ", error);
          reject();
        });
      })
    },
    async setActiveChapter({commit,state},c){
      return new Promise((resolve,reject)=>{
        commit("setChapter",c);
        resolve("done");
      })
    },
    async getActiveSectionContent({commit,state}){
      return new Promise((resolve,reject)=>{
        client.fetch(`*[_type == 'post' && categories[0]._ref == '${state.activeSection._id}'] | order(publishedAt) `)
        .then((data) => {
          state.activeSectionContent = data;
          resolve();
        })
        .catch((error) => {
          console.error("Oh no, error occured: ", error);
          reject();
        });
      })
    },

    async deleteItem({commit,state},{type,id}){
      return new Promise((resolve,reject)=>{
        console.log(type,id);
        db.collection("Contenido").doc(type).delete()
        .then(()=>{
          console.log("deleted");
          resolve("done");
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
          reject("error");
        });
        
      })
    },
    async template({commit,state}){
      return new Promise((resolve,reject)=>{
        
      })
    },
    openAcerca({state}){
      state.showAcerca = true;
    }
  },
  modules: {
  },
  getters: {
    getSections: state=>state.sections,
    getSectionById: (state) => (id) => {
      return state.sections.find(s => s.id === id)
    },
    getFondos: state=>state.fondos,
    getActiveSections: state=>state.sections.filter(s=>s.active),
    getContent:state=>state.content,
    getRevistas: state=>state.revistas,
    getBoletas: state=>state.boletas,
    getCapsulas: state=>state.capsulas,
    getDialogos: state=>state.dialogos,
    getComunicados: state=>state.comunicados,
    getCamps: state=>state.camps,
    getConecta: state=>state.conecta,
    getTLT: state=>state.tlt,
    getTitle: state=>state.activeTitle,
    getUser: state=>state.user,
    getChapter: state=>state.activeChapter,
  }
})
