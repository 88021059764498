<template>
  <div class="nav">
    <img alt="Impulso Logo" src="../assets/logo.png">
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.nav{
    margin: 0;
    padding: 0px;
    text-align: left;
    width: 100%;
    background-color:transparent;
    overflow: hidden;

    img{
        padding: 15px 20px;
        width:150px;
    }
}

</style>
