<template>
  <div class="footer">
    <div class="links">
      <ul>
        <li @click="openAcerca">Acerca</li>
        <li @click="openPrivacidad">Aviso de Privacidad</li>
        <li @click="openContacto">Contacto</li>
      </ul>
    </div>
    <div class="copy">
      <span>Impulso Digital &copy;2021</span>
    </div>
    <div class="popup" v-if="showPopup">
      <div class="acerca" v-if="showAcerca">
              <p>Impulso Digital es un desarrollo creado por la Dirección de Capital Humano y Relaciones Laborales a través de la Gerencia de Comunicación Interna</p>
      </div>
      <div class="contacto" v-if="showContacto">
        <p>Para cualquier tema relacionado con el acceso a la plataforma o con tu usuario y contraseña ponte en contacto aquí:</p> 
        <p> <a href="mailto:comunicacion_interna@montepiedad.com.mx">comunicacion_interna@montepiedad.com.mx</a></p>
        <p> Teléfono <a href="tel:+525552781700">5278-1700</a> ext. 4855</p>
      </div>
      <div class="cerrar" @click="close">OK</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  },
  data () {
    return{
      showPopup:false,
      showAcerca:false,
      showContacto:false,
    }
  },
  methods:{
    openAcerca(){
      //this.$store.dispatch("openAcerca");
      this.showPopup=true;
      this.showAcerca = true;
    },
    openPrivacidad(){
      window.open("https://firebasestorage.googleapis.com/v0/b/monteflix-33ebe.appspot.com/o/Aviso%20de%20privacidad%20Empleados%20y%20Beneficiarios%20del%20servicio%20me%CC%81dico-2020_Carta.pdf?alt=media&token=f9dd0f83-0a76-4553-9434-39c7408225c9","_blank");
    },
    openContacto(){
      this.showPopup = true;
      this.showContacto = true;
    },
    close(){
      this.showPopup=false;
      this.showAcerca=false;
      this.showContacto=false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer{
    margin: 0;
    padding: 0px;
    text-align: left;
    display: flex;
    justify-content:space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    background-color:transparent;
    overflow: hidden;
    font-size: .8rem;
    font-weight:300;
    overflow: hidden;

    .popup{
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0,0,0,0.4);
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items: center;
      font-size: 1.2rem;
    }

    a{
      color:#fff;
    }

    .acerca, .contacto{
      background-color:#000;
      color:#fff;
      width: 100%;
      height:200px;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items: center;
      padding: 15px 20px;
      @media screen and (min-width:760px) {
        width:50%;
        max-width: 700px;
      }
    }

    .contacto p{
      margin:10px auto;
    }

    .cerrar{
      background-color:#000;
      color:#fff;
      width: 100%;
      text-align: center;
      padding: 25px;
      cursor: pointer;
      @media screen and (min-width:760px) {
        width:50%;
        max-width: 700px;
      }
    }

    .cerrar:hover{
      font-size:1.25rem;
    }

    .copy{
      padding: 0 10px;
    }

    ul{
      list-style-type: none;
      display: flex;
      flex-direction: column ;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    li{
      margin: 10px;
      font-size: .7rem;
      cursor: pointer;
    }

    @media screen and (min-width:760px) {
        li{
          margin: 0 10px 5px 10px;
          font-size:.85rem;
        }

        ul{
          flex-direction: row;
        }
    }

    img{
        padding: 10px 20px;
        width:90px;
    }
}

</style>
